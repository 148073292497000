import { useLocation } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Path from '../routes/Path'
import { toast } from 'react-toastify'

export function calculateMonthDifference(startDate: string, endDate: string) {
  // Convert string dates to Date objects
  var startDateObj = new Date(startDate)
  var endDateObj = new Date(endDate)

  // Calculate year and month difference
  var yearDiff = endDateObj.getFullYear() - startDateObj.getFullYear()
  var monthDiff = endDateObj.getMonth() - startDateObj.getMonth()

  // Convert years to months and add to month difference
  monthDiff += yearDiff * 12

  // Return absolute value of the month difference
  return Math.abs(monthDiff)
}

export function displaySkillProgressColor(score: number) {
  if (score <= 50) {
    return '#F24822'
  } else if (score > 50 && score <= 64) {
    return '#FAAD14'
  } else if (score > 64 && score <= 78) {
    return '#17a2b8'
  } else {
    return '#14AE5C'
  }
}

export function useIsSectionalTest() {
  const location = useLocation()

  const isSectionalTest = location.pathname.startsWith(
    '/sectional-test/speaking',
  )

  return isSectionalTest
}

export function displaySkillProgressBarWidth(score: number) {
  return (score / 90) * 100
}

export function isTokenValid(token: string | null | undefined): boolean {
  if (!token) {
    return false
  }

  try {
    const decoded = jwtDecode(token)
    const currentTime = Date.now() / 1000

    // Check if the token has expired
    if (decoded.exp && decoded.exp < currentTime) {
      return false
    }

    return true
  } catch (error) {
    console.error('Invalid token:', error)
    return false
  }
}

export function calculateDaysDifference(inputDate: string | undefined): number {
  // Check if the inputDate is undefined, empty, or invalid
  if (!inputDate || inputDate === '') {
    return 0
  }

  const selectedDate = new Date(inputDate)

  // Check if the inputDate is a valid date
  if (isNaN(selectedDate.getTime())) {
    return 0
  }

  const today = new Date()

  // Resetting time to 00:00:00 for an accurate comparison of only the dates
  today.setHours(0, 0, 0, 0)
  selectedDate.setHours(0, 0, 0, 0)

  // Calculate the difference in time
  const timeDifference = selectedDate.getTime() - today.getTime()

  // Calculate the difference in days
  const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  // If the input date is less than today, return 0
  return dayDifference > 0 ? dayDifference : 0
}

export function calculateConfidenceMark(
  description: string,
  transcribedText: string,
): number {
  let matchedWordsCount = 0
  const regex = /[^a-zA-Z0-9_ -]/g

  // Clean and split the description and transcribed text into arrays of words
  const formattedDescription = description.toLowerCase().replace(regex, '')
  const formattedTranscribedText = transcribedText
    .toLowerCase()
    .replace(regex, '')

  const textDescriptionArray = formattedDescription.split(' ')
  const textTranscribedArray = formattedTranscribedText.split(' ')

  // Compare words and count matches
  for (let i = 0; i < textDescriptionArray.length; i++) {
    for (let j = 0; j < textTranscribedArray.length; j++) {
      if (textDescriptionArray[i] === textTranscribedArray[j]) {
        matchedWordsCount += 1
        break
      }
    }
  }

  // Calculate the confidence mark
  const confidenceMark =
    textTranscribedArray.length === 0
      ? 0
      : (matchedWordsCount * 5) / textTranscribedArray.length

  return confidenceMark
}

export function remvoveTestInformation(testType: number) {
  switch (testType) {
    // sectional test writing
    case 3: {
      localStorage.removeItem('buyIdWPTESectional')
      localStorage.removeItem('testIdWPTESectional')
      localStorage.removeItem('resultSectionalWPTE')
      break
    }
    // sectional test reading
    case 4: {
      localStorage.removeItem('testIdRPTESectional')
      localStorage.removeItem('buyIdRPTESectional')
      localStorage.removeItem('resultSectionalRPTE')
      break
    }
    // sectional test listening
    case 5: {
      localStorage.removeItem('testIdLPTESectional')
      localStorage.removeItem('buyIdLPTESectional')
      localStorage.removeItem('resultSectionalLPTE')
      break
    }
    // sectional test listening
    case 2: {
      localStorage.removeItem('testIdSPTESectional')
      localStorage.removeItem('buyIdSPTESectional')
      localStorage.removeItem('resultSectionalSPTE')
      break
    }
    // mock test
    case 1: {
      localStorage.removeItem('resultMockPTE')
      localStorage.removeItem('buyIdPTEMock')
      break
    }
  }
}

export function handleExceptionError(error: unknown): void {
  if (error instanceof TypeError) {
    console.error(error.message)
  } else {
    console.error('Something went wrong!')
  }
}

export const navigateToSectionalTestListening = (
  navigate: Function,
  categoryId: string,
) => {
  switch (categoryId) {
    case '17':
      navigate(Path.sectionalTest.summarizeSpokenText.path)
      break
    case '18':
      navigate(Path.sectionalTest.multipleChoiceChooseMultipleAnswers.path)
      break
    case '19':
      navigate(Path.sectionalTest.fillInTheBlanks.path)
      break
    case '20':
      navigate(Path.sectionalTest.highlightCorrectSummary.path)
      break
    case '21':
      navigate(Path.sectionalTest.multipleChoiceChooseSingleAnswer.path)
      break
    case '22':
      navigate(Path.sectionalTest.selectMissingWord.path)
      break
    case '23':
      navigate(Path.sectionalTest.highlightIncorrectWords.path)
      break
    case '24':
      navigate(Path.sectionalTest.writeFromDictation.path)
      break
    default:
      console.error('Invalid category ID')
  }
}

export const navigateToSectionalTestSpeaking = (
  navigate: Function,
  categoryId: string,
) => {
  switch (categoryId) {
    case '5':
      navigate(Path.sectionalTest.readAloud.path)
      break
    case '6':
      navigate(Path.sectionalTest.repeatSentence.path)
      break
    case '7':
      navigate(Path.sectionalTest.describeImage.path)
      break
    case '8':
      navigate(Path.sectionalTest.retellLecture.path)
      break
    case '9':
      navigate(Path.sectionalTest.answerShortQuestion.path)
      break
    default:
      console.error('Invalid category ID')
  }
}

export const navigateToMockTest = (
  navigate: Function,
  categoryId: string,
) => {
  switch (categoryId) {
    //Speaking
    case '5':
      navigate(Path.mockTest.readAloud.path)
      break
    case '6':
      navigate(Path.mockTest.repeatSentence.path)
      break
    case '7':
      navigate(Path.mockTest.describeImage.path)
      break
    case '8':
      navigate(Path.mockTest.retellLecture.path)
      break
    case '9':
      navigate(Path.mockTest.answerShortQuestion.path)
      break
    //Writing
    case '10':
      navigate(Path.mockTest.summarizeWrittenText.path)
      break
    case '11':
      navigate(Path.mockTest.writeEssay.path)
      break
    //Reading
    case '12':
      navigate(Path.mockTest.readingMultipleChoiceSingleAnswer.path) 
      break
    case '13':
      navigate(Path.mockTest.readingMultipleChoiceMultipleAnswer.path)
      break
    case '14':
      navigate(Path.mockTest.reorderParagraph.path)
      break
    case '15':
      navigate(Path.mockTest.readingFillInTheBlanks.path)
      break
    case '16':
      navigate(Path.mockTest.readingAndWritingFillInTheBlanks.path)
      break
    //Listening
    case '17':
      navigate(Path.mockTest.summarizeSpokenText.path)
      break
    case '18':
      navigate(Path.mockTest.multipleChoiceChooseMultipleAnswers.path)
      break
    case '19':
      navigate(Path.mockTest.fillInTheBlanks.path)
      break
    case '20':
      navigate(Path.mockTest.highlightCorrectSummary.path)
      break
    case '21':
      navigate(Path.mockTest.multipleChoiceChooseSingleAnswer.path)
      break
    case '22':
      navigate(Path.mockTest.selectMissingWord.path)
      break
    case '23':
      navigate(Path.mockTest.highlightIncorrectWords.path)
      break
    case '24':
      navigate(Path.mockTest.writeFromDictation.path)
      break
    default:
      console.error('Invalid category ID')
  }
}

export const toastError = (message: string) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: 'rgba(254, 226, 226, 1)', // bg-red-100
      color: 'rgba(220, 38, 38, 1)', // text-red-600
    },
  })
}

export const toastSuccess = (message: string) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  })
}

export const setupSpeechRecognition = (
  lang: string = 'en-US',
  continuous: boolean = true,
  interimResults: boolean = true
) => {
  // Check if SpeechRecognition API is supported
  if (window.SpeechRecognition || window.webkitSpeechRecognition) {
    const SpeechRecognitionClass = window.SpeechRecognition || window.webkitSpeechRecognition
    const speechRecognition = new SpeechRecognitionClass()
    
    // Set configurations
    speechRecognition.continuous = continuous
    speechRecognition.interimResults = interimResults
    speechRecognition.lang = lang

    return speechRecognition
  } else {
    console.error('Speech recognition not supported in this browser.')
    return null
  }
}

export const isRealExamStatus = (categoryType: number): boolean => {
  if (![12, 13,18, 20, 21, 22].includes(categoryType)) {
    return true
  } else {
    return false
  }
}

export const isWithinLastSevenDays = (dateString: string): boolean => {
  // Convert the input string to a Date object
  const inputDate = new Date(dateString);
  // Get the current date
  const currentDate = new Date();
  // Calculate 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(currentDate.getDate() - 7);
  
  // Check if the input date is within the range
  return inputDate >= sevenDaysAgo && inputDate <= currentDate;
}

