export const labelOptions = [
    {
        id: 'l-remove',
        type: 0,
        value: 3,
        label: 'Remove Label',
        color: '#808080'
    },
    {
        id: 'l-easy',
        type: 4,
        value: 7,
        label: 'Easy',
        color: '#28A745'
    },
    {
        id: 'l-normal',
        type: 3,
        value: 6,
        label: 'Normal',
        color: '#007BFF'
    },
    {
        id: 'l-hard',
        type: 2,
        value: 5,
        label: 'Hard',
        color: '#FFC107'
    },
    {
        id: 'l-no-idea',
        type: 1,
        value: 4,
        label: 'No Idea',
        color: '#FF4B5A'
    },
]