import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ProgressBar from '../ProgressBar'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import { deductCreditUser, practiceNowWithFilter, updateAttempt, getAllMockQuestions } from '../../services/practice.services'
import { handleExceptionError, setupSpeechRecognition, toastError } from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import SpeakingQuestionStates from './SpeakingQuestionStates'
import MockQuestion from '../../models/mockQuestion.model'
import { useNavigate } from 'react-router-dom'

type DescribeImageQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const DescribeImageQuestion: React.FC<DescribeImageQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const maxRecordingTime = 40
  const recordingTimeout = 25

  const [remainingTime, setRemainingTime] = useState(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(undefined)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [transcript, setTranscript] = useState<string>('')
  const [keywords, setKeywords] = useState<string>('')
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])

  useEffect(() => {
    setTimeout(async() => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')
      
      if (userProfile && userProfile.userId && questionId && questionType && questionLabel) {
        try {
          const response = await practiceNowWithFilter(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])

              const allMockQuestionsData = await getAllMockQuestions(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error("Failed to fetch all mock questions.")
              }
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
            setKeywords(response.data.pv)

            await updateAttempt(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [userProfile])

   // Use useMemo to initialize SpeechRecognition
   const recognition = useMemo(() => setupSpeechRecognition(), [])
   if (recognition) {
     recognition.onresult = (event: any) => {
       const finalTranscript = Array.from(event.results)
         .filter((result: any) => result.isFinal) 
         .map((result: any) => result[0].transcript) 
         .join(' ')
 
       setTranscript((prevText) => (prevText !== finalTranscript ? finalTranscript : prevText))
     }
 
     recognition.onerror = (event: any) => {
       console.error('Speech recognition error:', event.error)
     }
   }

  const startRecording = useCallback(() => {
      if (!isRecording && !audioBlob) {
        setIsRecording(true)
        setRecordingTime(maxRecordingTime)
        setRecordingProgress(0)
        setTranscript('') // Clear transcript before starting

        const beep = new Audio('/sound-beep.mp3')
        beep.play().catch((error) => {
          console.log('Beep sound play error:', error)
        })

        // Start MediaRecorder for audio recording
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            const newRecorder = new MediaRecorder(stream)
            recorderRef.current = newRecorder
            const chunks: BlobPart[] = []

            newRecorder.ondataavailable = (event) => chunks.push(event.data)
            newRecorder.onstop = () => {
              setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
              setRecordingProgress(100)
            }

            newRecorder.start()

            // Start SpeechRecognition for transcription
            if (recognition) {
              recognition.start()
            }
          })
          .catch((error) => {
            console.error('Error accessing microphone:', error)
          })
      }
    },
    [isRecording, audioBlob, recognition],
  )

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    if (userProfile && userProfile.userId && question) {
      try {
        const response = await deductCreditUser(userProfile.userId, question.category_id)
        if (!response.data.success) {
          console.error(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question is missing!')
    }
  }, [question, userProfile])

  const stopRecording = useCallback(() => {
    if (!isRecording || !recorderRef.current) return // Ensure it's only stopped once

    recorderRef.current.stop() // Stop the audio recording
    recorderRef.current = null // Clear the recorder reference
      
    setIsRecording(false)
    setRecordingTime(40)
    setRecordingProgress(100)
    setIsSubmitted(true)

    // Stop speech recognition
    if (recognition) {
      recognition.stop()
    }

    handleSubmitAnswer()
  }, [handleSubmitAnswer, isRecording, recognition])

  const handleSkip = () => {
    if (!isRecording && remainingTime > 0) {
      startRecording()
      setRemainingTime(0)
    }
  }

  const resetState = () => {
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(40)
    setRemainingTime(25)
    setRecordingProgress(0)
    setTranscript('')
    setIsSubmitted(false)
    setShowScoreBoard(false)
  }

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)
      return () => clearTimeout(timerId)
    } else if (remainingTime === 0 && !isRecording && !isSubmitted) {
      startRecording()
    }
  }, [remainingTime, isRecording, startRecording, isSubmitted])

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording && recordingTime > 0) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          setRecordingProgress(((40 - prevTimer) / 40) * 100)
          return prevTimer - 1
        })
      }, 1000)
      return () => clearInterval(intervalId)
    } else if (recordingTime <= 0 && isRecording) {
      stopRecording()
    }
  }, [isRecording, recordingTime, stopRecording])

  const handleSaveAndNext = () => {
    if (!isSubmitted) {
      stopRecording() // Stop recording
    }
  }

  const highlightKeywords = (transcript: string, keywords: string): React.ReactNode => {
    const keywordSet = new Set(keywords.toLowerCase().split(' ')) // Create a set of keywords
    const words = transcript.split(/(\s+)/) // Split by words and keep spaces
  
    return words.map((word, index) => {
      const cleanWord = word.replace(/[^\w]/g, '') // Remove punctuation for comparison
      const isKeyword = keywordSet.has(cleanWord.toLowerCase()) // Check if the word is a keyword
      return (
        <span
          key={index}
          style={{ 
            color: isKeyword ? 'green' : 'inherit',
            fontWeight: isKeyword ? 'bold' : 'inherit',
          }}
        >
          {word}
        </span>
      )
    })
  }

  const handleQuestionChange  = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || (position + 1) !== allMockQuestions.length) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    }
    else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if ((position + 1) === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  return (
    <div>
    {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <>
          {!isRecording && !audioBlob && (
            <div className="block md:flex justify-between">
              <div className="flex text-center items-center mb-2 md:mb-0">
                <p className="text-h5m text-neutrals-2 mr-2">Beginning in:</p>
                <p className="text-h3m text-neutrals-1">{remainingTime} seconds</p>
              </div>
              <button
                onClick={handleSkip}
                className="text-bodyr text-neutrals-1 p-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
              >
                Skip to Test
              </button>
            </div>
          )}
          <p className="text-bodym text-danger mt-2 md:mt-8">
            *This question carries marks for Speaking only ( ~25%)
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">
              {question?.short_title}
            </p>
            <div className="w-full">
              <img
                src={question && question.image ? question.image : ''}
                alt="Weekly speaking images"
                className="mx-auto my-4"
              />
            </div>
            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl lg:w-[50%] w-full mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>
                <ProgressBar progress={recordingProgress} />
                <button
                  onClick={handleSaveAndNext}
                  className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
                >
                  Stop
                </button>
              </div>
            )}
            <SpeakingQuestionStates question={question} />
            <div
              className={`flex items-center justify-center mt-4 cursor-pointer ${!showScoreBoard ? 'block' : 'hidden'}`}
              onClick={() => setShowScoreBoard(true)}
            >
              <img
                src={ReadAloudArrowSvg}
                alt="arrow"
                className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
              />
              <p className="text-bodyr text-info ml-2">Show Score Board</p>
            </div>{' '}
          </div>

          <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
            <button 
              className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isFirstQuestion() ? 'invisible' : ''}`}
              onClick={displayPreviousQuestion}
            >
              <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
              <p className="hidden md:block text-bodyr text-white">Previous</p>
            </button>
            <button
              className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
              onClick={resetState}
            >
              <p className="hidden md:block text-bodyr text-white">Try Again</p>
              <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
            </button>
            <div>
              <select 
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
              {allMockQuestions.map((mockQuestion, index) => (
                <option
                  key={mockQuestion.id}
                  value={mockQuestion.id}
                  className="text-bodyr"
                >
                  {index + 1}
                </option>
              ))}
              </select>
            </div>

            <button 
              className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isLastQuestion() ? 'invisible' : ''}`}
              onClick={displayNextQuestion}
            >
              <p className="hidden md:block text-bodyr text-white">Next</p>
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>
          </div>

          {
            isSubmitted && (
              <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                  <p className="text-h4m">Original Audio</p>
                  <audio
                    controls
                    src=""
                    className="my-4 w-full"
                  />
                  <p>
                    <span className="font-bold">Keywords:</span> {keywords}
                  </p>
                </div>
                <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                  <p className="text-h4m">Your Audio</p>
                  {audioBlob && (
                    <>
                      <audio
                        controls
                        src={URL.createObjectURL(audioBlob)}
                        className="my-4 w-full"
                      />
                      <p>
                        <span className="font-bold">Your answer: </span>
                        <span>{highlightKeywords(transcript, keywords)}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            )
          }
        </>
      )
    }
    </div>
  )
}

export default DescribeImageQuestion