import React, { useState, useEffect, useRef } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { useNavigate } from 'react-router-dom'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import { practiceNowWithFilter, getAllMockQuestions, updateAttempt } from '../../services/practice.services'
import { handleExceptionError, toastError } from '../../utils/utils'
import ArrayUtils from '../../utils/ArrayUtils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ReadingQuestionStates from './ReadingQuestionStates'

interface Column {
  name: string
  items: Option[]
}

interface Columns {
  [key: string]: Column
}

const initialColumns: Columns = {
  leftPanel: {
    name: 'Source Panel',
    items: [],
  },
  rightPanel: {
    name: 'Target Panel',
    items: [],
  },
}

const onDragEnd = (
  result: DropResult,
  columns: Columns,
  setColumns: React.Dispatch<React.SetStateAction<Columns>>,
) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}

const ReorderParagraphQuestion = () => {
  const [columns, setColumns] = useState(initialColumns)
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(undefined)
  const [options, setOptions] = useState<Option[]>([])
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(async() => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')
      
      if (userProfile && userProfile.userId && questionId && questionType && questionLabel) {
        try {
          const response = await practiceNowWithFilter(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOptions(response.data.option)

              // Map the options to the format expected by `columns.leftPanel.items`
              let leftPanelItems: Option[] = response.data.option.map((option: Option) => ({
                id: option.id, 
                content: option.options,
                altOption: option.altOption,
                correct: option.correct,
                options: option.options
              }))

              leftPanelItems = ArrayUtils.shuffleArray(leftPanelItems)

              // Update columns with the new leftPanel items
              setColumns((prevColumns) => ({
                ...prevColumns,
                leftPanel: {
                  ...prevColumns.leftPanel,
                  items: leftPanelItems,
                },
              }))

              const allMockQuestionsData = await getAllMockQuestions(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error("Failed to fetch all mock questions.")
              }

              await updateAttempt(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [userProfile])

  const resetState = () => {
    // Shuffle the options and move them back to the left panel
    const shuffledItems = ArrayUtils.shuffleArray([...columns.leftPanel.items, ...columns.rightPanel.items])
    
    setColumns({
      ...columns,
      leftPanel: {
        ...columns.leftPanel,
        items: shuffledItems,
      },
      rightPanel: {
        ...columns.rightPanel,
        items: [],
      },
    })
  
    setIsSubmitted(false) 

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const handleQuestionChange  = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || (position + 1) !== allMockQuestions.length) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    }
    else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if ((position + 1) === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {
        isLoading ? (
          <LoadingMessage message='Loading question...'/> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) :  (
          <>
            <p className="text-bodym text-danger mt-2 md:mt-8">
              *This question carries marks for Reading only (~12%)
            </p>

            <div 
              className="p-8 rounded-xl shadow mt-8"
              ref={questionSectionRef}
            >
              <p className="text-h4m text-neutrals-1">
                { question?.short_title }
              </p>

              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
              >
                <div className="pt-8 mx-auto">
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(columns).map(([columnId, column], index) => (
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="col-span-1 bg-gray-200 p-4 rounded-lg"
                            style={{
                              height: '650px',
                              overflowY: 'auto',
                            }}
                          >
                            <h2 className="text-h5m">{column.name}</h2>
                            {column.items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={String(item.id)}
                                index={index}
                                isDragDisabled={isSubmitted}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`p-4 my-2 bg-white rounded shadow ${
                                      snapshot.isDragging ? 'bg-blue-100' : 'bg-white'
                                    }`}
                                  >
                                    {item.options}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                </div>

                <div className="flex place-content-center mt-8">
                  <button 
                    className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 ${(columns.rightPanel.items.length !== options.length || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                    onClick={() => setIsSubmitted(true)}
                  >
                    <img
                      src={WeeklyPredictionSubmitIcon}
                      alt="submit"
                      className="mr-2"
                    />
                    Submit
                  </button>
                </div>

                <ReadingQuestionStates question={question}/>
              </DragDropContext>
            </div>

            <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
              <button 
                className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isFirstQuestion() ? 'invisible' : ''}`}
                onClick={displayPreviousQuestion}
              >
                <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
                <p className="hidden md:block text-bodyr text-white">Previous</p>
              </button>
              <button 
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">Try Again</p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
              <div>
                <select 
                  className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                  defaultValue={question?.id}
                  onChange={(e) => handleQuestionChange(e.target.value)}
                >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
                </select>
              </div>
              <button 
                className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isLastQuestion() ? 'invisible' : ''}`}
                onClick={displayNextQuestion}
              >
                <p className="hidden md:block text-bodyr text-white">Next</p>
                <img src={NextArrowSvg} alt="next" className="md:ml-2" />
              </button>
            </div>
            {
              isSubmitted && (
                <div ref={answerSectionRef} className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                  <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                    <p className="text-h4m text-center">Original Answer</p>
                    <div className="flex flex-col gap-4 mt-[1rem]">
                      {
                        options.map((option: Option, index) => (
                          <div key={index} className="p-2 bg-white text-green-700 border-2 border-green-700 rounded-lg">{option.options}</div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                    <p className="text-h4m text-center">Your Answer</p>
                    <div className="flex flex-col gap-4 mt-[1rem]">
                      {
                        columns.rightPanel.items.map((option: Option, index) => (
                          <div key={index} className={`p-2 bg-white rounded-lg ${option.correct === (index + 1) ? 'text-green-700 border-2 border-green-700' :'text-red-600 border-2 border-red-600'}`}>{option.options}</div>
                        ))
                      }
                    </div>
                  </div>
                </div>     
              )
            }
          </>
        )
      }
    </div>
  )
}

export default ReorderParagraphQuestion
