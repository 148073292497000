import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import ProgressBar from '../ProgressBar'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import { practiceNowWithFilter, deductCreditUser, getAllMockQuestions } from '../../services/practice.services'
import { handleExceptionError, setupSpeechRecognition, toastError } from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import SpeakingQuestionStates from './SpeakingQuestionStates'
import MockQuestion from '../../models/mockQuestion.model'
import { useNavigate } from 'react-router-dom'

type ReTellLectureQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const ReTellLectureQuestion: React.FC<ReTellLectureQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const maxRecordingTime = 40
  const recordingTimeout = 5
  const postAudioDelay = 10

  const [initialDelay, setInitialDelay] = useState<null | number>(recordingTimeout)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(undefined)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [transcript, setTranscript] = useState<string>('')
  const [audioPlayError, setAudioPlayError] = useState(false)
  const [postAudioCountdown, setPostAudioCountdown] = useState<null | number>(null) // Added state for post-audio delay
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])

  useEffect(() => {
    setTimeout(async() => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')
      
      if (userProfile && userProfile.userId && questionId && questionType && questionLabel) {
        try {
          const response = await practiceNowWithFilter(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])

              const allMockQuestionsData = await getAllMockQuestions(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error("Failed to fetch all mock questions.")
              }
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [userProfile])

  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => setupSpeechRecognition(), [])
  if (recognition) {
    recognition.onresult = (event: any) => {
      const finalTranscript = Array.from(event.results)
        .filter((result: any) => result.isFinal) 
        .map((result: any) => result[0].transcript) 
        .join(' ')

      setTranscript((prevText) => (prevText !== finalTranscript ? finalTranscript : prevText))
    }

    recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error)
    }
  }

  const startRecording = useCallback(() => {
    if (!isRecording && !audioBlob) {
      setIsRecording(true)
      setRecordingTime(40)
      setRecordingProgress(0)
      setTranscript('')

      const beep = new Audio('/sound-beep.mp3')
      beep.play().catch((error) => {
        console.log('Beep sound play error:', error)
      })

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newRecorder = new MediaRecorder(stream)
          recorderRef.current = newRecorder
          const chunks: BlobPart[] = []

          newRecorder.ondataavailable = (event) => chunks.push(event.data)
          newRecorder.onstop = () => {
            setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
            setRecordingProgress(100)
          }
          newRecorder.start()

          if (recognition) {
            recognition.start()
          }
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error)
        })
    }
  }, [isRecording, audioBlob, recognition])

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    if (userProfile && userProfile.userId && question) {
      try {
        const response = await deductCreditUser(userProfile.userId, question.category_id)
        if (!response.data.success) {
          console.error(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('Userprofile or question is missing!')
    }
  }, [question, userProfile])

  const stopRecording = useCallback(() => {
    if (!isRecording || !recorderRef.current) return // Ensure it's only stopped once

    recorderRef.current.stop() // Stop the audio recording
    recorderRef.current = null // Clear the recorder reference
      
    setIsRecording(false)
    setRecordingTime(40)
    setRecordingProgress(100)
    setIsSubmitted(true)

    // Stop speech recognition
    if (recognition) {
      recognition.stop()
    }

    handleSubmitAnswer()
  }, [isRecording, recognition, handleSubmitAnswer])

  const resetState = () => {
    setIsSubmitted(false)
    setInitialDelay(2)
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(40)
    setRecordingProgress(0)
    setTranscript('')
    setShowScoreBoard(false)
    setPostAudioCountdown(null)
    setAudioPlayError(false)
    audioRef.current &&
      (audioRef.current.src =
        question?.audio || '')
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          let newTime = prevTimer - 1
          setRecordingProgress(((40 - newTime) / 40) * 100)
          if (newTime > 0) {
            return newTime
          } else {
            clearInterval(intervalId)
            stopRecording()
            return 0
          }
        })
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording, stopRecording])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (initialDelay && initialDelay > 0) {
      timerId = setTimeout(() => setInitialDelay(initialDelay - 1), 1000)
    } else if (initialDelay === 0) {
      audioRef.current?.play().catch(() => {
        setAudioPlayError(true) // Set error state if play fails
      })
      setInitialDelay(null)
    }
    return () => clearTimeout(timerId)
  }, [initialDelay])

  // Start post-audio countdown
  useEffect(() => {
    if (!initialDelay && audioRef.current) {
      audioRef.current.addEventListener('ended', () => {
        setPostAudioCountdown(postAudioDelay)
      })
    }
  }, [initialDelay])

  // Handle post-audio countdown logic
  useEffect(() => {
    let countdownId: NodeJS.Timeout;
    if (postAudioCountdown && postAudioCountdown > 0) {
      countdownId = setTimeout(() => setPostAudioCountdown(postAudioCountdown - 1), 1000)
    } else if (postAudioCountdown === 0) {
      setPostAudioCountdown(null)
      startRecording()
    }
    return () => clearTimeout(countdownId)
  }, [postAudioCountdown, startRecording])

  const handlePlayAudio = () => {
    audioRef.current
      ?.play()
      .then(() => {
        setAudioPlayError(false) // Reset error state if audio plays successfully
      })
      .catch(() => {
        setAudioPlayError(true) // Set error state if play fails
      })
  }

  const handleQuestionChange  = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || (position + 1) !== allMockQuestions.length) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    }
    else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if ((position + 1) === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  return (
    <div>
    {
      isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <>
          <p className="text-bodym text-danger mt-2 md:mt-8">
            * This question carries marks for Speaking (~17%) and Listening (~15%)
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">
              {question?.short_title}
            </p>
            {!isRecording && !audioBlob && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl lg:w-[50%] w-full mx-auto mt-4">
                {initialDelay ? (
                  <p className="text-h4m text-neutrals-1">
                    Audio will start in: {initialDelay} seconds
                  </p>
                ) 
                : postAudioCountdown ? (
                  <p className="text-h4m text-neutrals-1">
                    Recording will start in: {postAudioCountdown} seconds
                  </p>
                ) 
                : audioPlayError ? (
                  <p className="text-red-600 text-footnote w-full text-center">
                    Oops! It seems the audio didn't start automatically. No
                    worries—just click{' '}
                    <button
                      onClick={handlePlayAudio}
                      className="underline text-blue-600 bg-yellow-200 px-2 rounded-lg"
                    >
                      Here
                    </button>{' '}
                    to play it manually!
                  </p>
                )
                : (
                  <>
                    <p className="text-h4m text-neutrals-1">Original Audio</p>
                  </>
                )}
                {!postAudioCountdown && ( // Hide audio during post-audio countdown
                  <audio
                    ref={audioRef}
                    src={question && question.audio ? question.audio : ''}
                    preload="auto"
                    controls
                    className="my-4 w-full"
                  ></audio>
                )}
              </div>
            )}
            {isRecording && (
              <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                <p className="text-h5r mt-4">Timer: {recordingTime} seconds</p>

                <ProgressBar progress={recordingProgress} />

                <button
                  onClick={stopRecording}
                  className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
                >
                  Stop Recording
                </button>
              </div>
            )}

            <SpeakingQuestionStates question={question} />
            <div
              className={`flex items-center justify-center mt-4 cursor-pointer ${!showScoreBoard ? 'block' : 'hidden'}`}
              onClick={() => setShowScoreBoard(true)}
            >
              <img
                src={ReadAloudArrowSvg}
                alt="arrow"
                className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
              />
              <p className="text-bodyr text-info ml-2">Show Score Board</p>
            </div>
          </div>

          <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
            <button 
              className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isFirstQuestion() ? 'invisible' : ''}`}
              onClick={displayPreviousQuestion}
            >
              <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
              <p className="hidden md:block text-bodyr text-white">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer mx-4"
                onClick={resetState}
              >
                <p className="hidden md:block ext-bodyr text-white">Try Again</p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select 
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
              {allMockQuestions.map((mockQuestion, index) => (
                <option
                  key={mockQuestion.id}
                  value={mockQuestion.id}
                  className="text-bodyr"
                >
                  {index + 1}
                </option>
              ))}
              </select>
            </div>

            <button 
              className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isLastQuestion() ? 'invisible' : ''}`}
              onClick={displayNextQuestion}
            >
              <p className="hidden md:block text-bodyr text-white">Next</p>
              <img src={NextArrowSvg} alt="next" className="md:ml-2" />
            </button>
          </div>
          {
            isSubmitted && (
              <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                  <p className="text-h4m">Original Audio</p>
                  <audio
                    controls
                    src={question?.audio}
                    preload="auto"
                    className="my-4 w-full"
                  />
                  <p>
                    <span className="font-bold">Original transcript: </span>
                    <span>{question?.description}</span>
                  </p>
                </div>
                <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                  <p className="text-h4m">Your Audio</p>
                  {audioBlob && (
                    <>
                      <audio
                        controls
                        src={URL.createObjectURL(audioBlob)}
                        className="my-4 w-full"
                      />
                      <p>
                        <span className="font-bold">Your answer: </span>
                        <span>{transcript}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            )
          }      
        </>
      )
    }
    </div>
  )
}

export default ReTellLectureQuestion
