import logo from '../images/Footer/logo-svg.svg'
import facebookIcon from '../images/Footer/facebook-icon.svg'
import twitterIcon from '../images/Footer/twitter-icon.svg'
import instagramIcon from '../images/Footer/instagram-icon.svg'
import githubIcon from '../images/Footer/github-icon.svg'
const Footer = () => {
  return (
    <footer className="w-full bg-primary-6 text-white">
      <div className="flex gap-4 py-6 px-2 w-full sm:w-[90%] mx-auto justify-between items-center">
        <div>
          <img src={logo} alt="Logo" className="w-[170px] h-[70px]" />
          <div className="text-bodyr">
            <p>
              <b>Email:</b>
              <span> </span>
              <a
                href="mailto:admin@masterpte.com.au"
                target="_blank"
                rel="noreferrer"
              >
                admin@masterpte.com.au
              </a>
            </p>
            <p>
              <b>Address:</b>
              <span> </span>
              Level 36 Gateway, 1 Macquarie Place
            </p>
            <p>Sydney NSW 2000 Sydney, Australia</p>
            <p>
              <b>Contact Number:</b> <span> </span>
              0411 703 756
            </p>
          </div>

          <div className="flex gap-4 pt-4">
            <a
              href="https://www.facebook.com/PTEMASTER/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/PteMaster/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a
              href="https://www.instagram.com/masterpte/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCaK4e33DZ5noorXXOTMaJ2g"
              target="_blank"
              rel="noreferrer"
            >
              <img src={githubIcon} alt="Github" />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <p className="text-h5b">Company</p>
          <div className="text-bodyr flex flex-col gap-1">
            <a href="/about-us">About us</a>
            <a href="/careers">Careers</a>
            <a href="/support">Support</a>
            <a href="/testimonials">Testimonials</a>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/cookie-policy">Cookie Policy</a>
          </div>
        </div>
      </div>

      <div className="bg-primary-9 text-white py-4">
        <div className="w-[90%] mx-auto flex flex-col sm:flex-row justify-between">
          <p>© 2024 PTE Master. All rights reserved.</p>
          <p>
            Contact us:{' '}
            <a
              href="mailto:admin@masterpte.com.au"
              target="_blank"
              rel="noreferrer"
            >
              admin@masterpte.com.au
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
