export enum QuestionModel {
  S_ReadAloud = 'Read Aloud',
  S_RepeatSentence = 'Repeat Sentence',
  S_DescribeImage = 'Describe Image',
  S_RetellLecture = 'Re-tell Lecture',
  S_AnswerShortQuestion = 'Answer Short Question',
  W_SummarizeWrittenText = 'Summarize Written Text',
  W_WriteEssay = 'Write Essay',
  R_MultipleChoiceChooseSingleAnswer = 'Multiple-choice, choose single answer',
  R_MultipleChoiceChooseMultipleAnswers = 'Multiple-choice, choose multiple answer',
  R_ReorderParagraphs = 'Re-order paragraphs',
  R_ReadingFillInTheBlanks = 'Reading: Fill in the blanks',
  R_ReadingAndWritingFillInTheBlanks = 'Reading and writing: Fill in the blanks',
  L_SummarizeSpokenText = 'Summarize Spoken Text',
  L_MultipleChoiceChooseMultipleAnswers = 'Multiple-choice, choose multiple answers"',
  L_FillInTheBlanks = 'Fill in the blanks',
  L_HighlightCorrectSummary = 'Highlight correct summary',
  L_MultipleChoiceChooseSingleAnswer = 'Multiple-choice, choose single answer',
  L_SelectMissingWord = 'Select missing word"',
  L_HighlightIncorrectWords = 'Highlight incorrect words',
  L_WriteFromDictation = 'Write from dictation',
}

export enum QuestionModelId {
  S_ReadAloud = '5',
  S_RepeatSentence = '6',
  S_DescribeImage = '7',
  S_RetellLecture = '8',
  S_AnswerShortQuestion = '9',
  W_SummarizeWrittenText = '10',
  W_WriteEssay = '11',
  R_MultipleChoiceChooseSingleAnswer = '12',
  R_MultipleChoiceChooseMultipleAnswers = '13',
  R_ReorderParagraphs = '14',
  R_ReadingFillInTheBlanks = '15',
  R_ReadingAndWritingFillInTheBlanks = '16',
  L_SummarizeSpokenText = '17',
  L_MultipleChoiceChooseMultipleAnswers = '18',
  L_FillInTheBlanks = '19',
  L_HighlightCorrectSummary = '20',
  L_MultipleChoiceChooseSingleAnswer = '21',
  L_SelectMissingWord = '22',
  L_HighlightIncorrectWords = '23',
  L_WriteFromDictation = '24',
}

export enum SkillName {
  Reading = 'Reading',
  Listening = 'Listening',
  Speaking = 'Speaking',
  Writing = 'Writing',
}

export enum Status {
  TokenRequired = 'Token Required',
  FreeCourse = 'Free Course',
  SoldOut = 'Sold Out',
  Available = 'Available',
}

export enum PracticeType {
  AllQuestions = 'All Questions',
  AttemptedQuestions = 'Attempted Questions',
  NotAttemptedQuestions = 'Not Attempted Questions',
}
export enum Type {
  All = 'All',
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
}

export interface SpeakingQuestion {
  id: string
  name: string
  description: string
  practiceType: PracticeType
  type: Type
}

export interface Test {
  name: QuestionModel
  zone: SkillName
  amount: number
  status: Status
}

export interface Category {
  name: SkillName
  questionModels: QuestionModel[]
}

export interface Category2 {
  name: SkillName
  questionModels: {
    id: QuestionModelId
    name: QuestionModel
  }[]
}
