import React, { useState } from 'react'
import QuestionModalAccordion from '../../components/QuestionModalAccordion'
import SpeakingQuestionList from '../../components/SpeakingCourse/SpeakingQuestionList'
import ReadingQuestionList from '../../components/ReadingCourse/ReadingQuestionList'
import { QuestionModelId } from '../../models/QuestionModels'

const practiceTypes = [
  {
    id: 0,
    value: 'All Questions',
  },
  {
    id: 1,
    value: 'Attempted questions',
  },
  {
    id: 2,
    value: 'Not attempted questions',
  }
]

const labelTypes = [
  {
    id: 0,
    value: 'All',
    color: '#808080'
  },
  {
    id: 4,
    value: 'Easy',
    color: '#28A745'
  },
  {
    id: 3,
    value: 'Normal',
    color: '#007BFF'
  },
  {
    id: 2,
    value: 'Hard',
    color: '#FFC107'
  },
  {
    id: 1,
    value: 'No Idea',
    color: '#FF4B5A'
  },
]

const WeeklyPredictionCourse = () => {
  let questionTypePTE = localStorage.getItem('questionTypePTE')

  const [questionType, setQuestionType] = useState<QuestionModelId>(
    (questionTypePTE as QuestionModelId) || QuestionModelId.S_ReadAloud,
  )
  const [practiceSelection, setPracticeSelection] = useState<number>(0)
  const [labelSelection, setLabelSelection] = useState<number>(0)

  const handlePracticeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const selectedValue = event.target.value
    setPracticeSelection(Number(selectedValue))
    // localStorage.setItem('ptePracticeQuestionAttempt', selectedValue.toString());
  }

  const handleTypeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const selectedValue = event.target.value
    setLabelSelection(Number(selectedValue))
    // localStorage.setItem('ptePracticeQuestionLabel', labelSelection.toString())
  }

  return (
    <div>
      <div className="bg-primary-9 h-[320px] content-center text-center">
        <p className="text-h1 text-primary-1">Speaking Test</p>
        <p className="text-bodyr text-primary-2 pt-8 lg:mx-[32%]">
          Mattis rhoncus urna neque viverra justo. Ullamcorper dignissim cras
          tincidunt lobortis feugiat vivamus. Neque vitae tempus quam
          pellentesque nec. Mi bibendum neque egestas congue quisque egestas
          diam in.
        </p>
      </div>
      <div className="p-4 xl:p-10 grid grid-cols-8 gap-8">
        <div className="col-span-8 lg:col-span-2 flex flex-col-reverse lg:flex-col">
          <div className="lg:border-b border-[2px]-[#BFBFBF] pb-2 pt-2 lg:pt-0">
            <p className="text-h5m uppercase">Practice</p>
            {
              practiceTypes.map((practiceType, index) => (
                <div className="flex items-center my-2" key={index}>
                  <input
                    type="radio"
                    id={practiceType.value}
                    className="h-4 w-4"
                    name="practice"
                    value={practiceType.id}
                    checked={practiceSelection === practiceType.id}
                    onChange={handlePracticeChange}
                  />
                  <label htmlFor={practiceType.value} className="text-bodyr pl-2">{practiceType.value}</label>
                </div>
              ))
            }
          </div>
          <div className="border-b border-[2px]-[#BFBFBF] py-2">
            <p className="text-h5m uppercase">Type</p>
            {
              labelTypes.map((labelType, index) => (
                <div className="flex items-center my-2" key={index}>
                  <input
                    type="radio"
                    id={labelType.value}
                    className="h-4 w-4"
                    name="type"
                    value={labelType.id}
                    checked={labelSelection === labelType.id}
                    onChange={handleTypeChange}
                  />
                  <label htmlFor={labelType.value} style={{ color: labelType.color }} className="text-bodyr pl-2">{labelType.value}</label>
                </div>
              ))
            }
          </div>
          <div>
            <p className="text-h5m uppercase pt-2">Zone</p>
            <QuestionModalAccordion setQuestionType={setQuestionType} />
          </div>
        </div>

        <div className="col-span-8 lg:col-span-6">
          {[
            QuestionModelId.S_ReadAloud,
            QuestionModelId.S_RepeatSentence,
            QuestionModelId.S_DescribeImage,
            QuestionModelId.S_RetellLecture,
            QuestionModelId.S_AnswerShortQuestion].includes(questionType) ? (
              <SpeakingQuestionList questionType={questionType} practiceType={practiceSelection} labelType={labelSelection}/>
            ) :
            [
              QuestionModelId.R_ReorderParagraphs,
              QuestionModelId.R_ReadingFillInTheBlanks,
              QuestionModelId.R_ReadingAndWritingFillInTheBlanks].includes(questionType) ? (
                <ReadingQuestionList questionType={questionType} practiceType={practiceSelection} labelType={labelSelection}/>
            ) : (
              <p>Coming soon!</p>
            )
          } 
        </div>
      </div>
    </div>
  )
}

export default WeeklyPredictionCourse
