import React, { useState, useEffect, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import PreviousArrowSvg from '../../images/Speaking/previous-arrow.svg'
import NextArrowSvg from '../../images/Speaking/next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { useNavigate } from 'react-router-dom'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import { practiceNowWithFilter, updateAttempt, getAllMockQuestions } from '../../services/practice.services'
import { handleExceptionError, toastError } from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ReadingQuestionStates from './ReadingQuestionStates'

const FillInTheBlankQuesiton = () => {
  const navigate = useNavigate()
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(undefined)
  const [originalDescription, setOriginalDescription] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number]: string }>({})
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const { userProfile } = useAuth()
  const answerSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(async() => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')
      
      if (userProfile && userProfile.userId && questionId && questionType && questionLabel) {
        try {
          const response = await practiceNowWithFilter(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOptions(response.data.option)
              setOriginalDescription(mockQuestions[0].description || '')

              const allMockQuestionsData = await getAllMockQuestions(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error("Failed to fetch all mock questions.")
              }

              await updateAttempt(userProfile.userId, Number(questionType), Number(questionId), 0, 0, 0, Number(questionLabel), 4)
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [userProfile])

  // Helper function to filter options by sequence
  const getOptionsBySequence = (sequence: number) => {
    return options
      .filter((opt) => opt.sequence === sequence)
      .map((opt) => (
        <option key={opt.id} value={opt.options}>
          {opt.options}
        </option>
      ))
  }

   // Handle change in selected option
   const handleSelectChange = (sequence: number, value: string) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [sequence]: value, // Update the selected answer for the given sequence
    }))
  }

   // Helper function to replace placeholders with <select> elements
   const generateFillInTheBlanks = (text: string): JSX.Element[] => {
    const parts = text.split(/\{(\d+)\}/g)
    
    return parts.map((part, index) => {
      if (!isNaN(Number(part))) {
        const sequenceNumber = Number(part)
        // If part is a number, render a <select> element
        return (
          <select
            key={index}
            onChange={(e) => handleSelectChange(sequenceNumber, e.target.value)} 
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px',
              margin: '0 5px',
            }}
            disabled={isSubmitted}
          >
            <option value="">--Select--</option>
            {getOptionsBySequence(sequenceNumber)}
          </select>
        )
      }
      // Otherwise, return the text part as is
      return <span key={index}>{part}</span>
    })
  }

  // Function to check if all options are filled
  const areAllOptionsFilled = () => {
    const placeholderMatches = question && question.description ? question.description.match(/\{(\d+)\}/g) : []
      
    // Count of placeholders
    const placeholderCount = placeholderMatches ? placeholderMatches.length : 0

    // Compare the number of selected answers with total placeholders
    return (
      Object.keys(selectedAnswers).length === placeholderCount &&
      Object.values(selectedAnswers).every(value => value !== "")
    )
  }

  const displayOriginalAnswer = (): string => {
    const originalAnswers = options
      .filter(item => item.correct === 1) // Filter correct answers
      .map(item => item.options)      // Get the options of correct answers
  
    return originalAnswers.reduce((updatedText, originalAnswer, index) => {
      // Construct the placeholder for each answer (e.g., {1}, {2})
      const placeholder = `{${index + 1}}`
  
      // Replace the placeholder with the styled answer
      return updatedText.replace(
        placeholder,
        `<span class="font-bold text-green-600 bg-green-200">${originalAnswer.trim()}</span>`
      )
    }, originalDescription)
  }

  const displayUserAnswer = (): string => {
    const originalAnswers = options
      .filter(item => item.correct === 1) // Filter correct answers
      .map(item => item.options) 

    const userAnswers = Object.values(selectedAnswers)

    return userAnswers.reduce((updatedText, answer, index) => {
      const placeholder = `{${index + 1}}`
      let conditionDisplayRender = ''
      if (originalAnswers[index].trim() === answer.trim()) {
        conditionDisplayRender = `<span class="font-bold text-green-600 bg-green-200">${answer.trim()}</span> `
      } else {
        conditionDisplayRender = `<span class="font-bold text-red-600 bg-red-200">${answer.trim()}</span> `
      }

      return updatedText.replace(placeholder, conditionDisplayRender)
    }, originalDescription)
  }

  const handleQuestionChange  = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)
    if (allMockQuestions.length === 0 || position === -1 || (position + 1) !== allMockQuestions.length) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    }
    else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(mockQuestion => mockQuestion.id === question?.id)

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if ((position + 1) === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {
        isLoading ? (
          <LoadingMessage message='Loading question...'/> 
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) :  (
          <>
            <p className="text-bodym text-danger mt-2 md:mt-8">
              *This question carries marks for Reading only (~25%)
            </p>

            <div className="p-8 rounded-xl shadow mt-8">
              <p className="text-h4m text-neutrals-1">
                { question?.short_title }
              </p>
              <div className="text-h5r text-neutrals-1 pt-4 leading-10">
                { question && question.description ? generateFillInTheBlanks(question.description) : '' }
              </div>

              <div className="w-[90%] lg:w-[60%] pt-4 mx-auto">
                <div className="flex place-content-center	mt-8">
                  <button 
                    className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 ${(!areAllOptionsFilled() || isSubmitted) ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                    onClick={() => setIsSubmitted(true)}
                    disabled={!areAllOptionsFilled() || isSubmitted}
                  >
                    <img
                      src={WeeklyPredictionSubmitIcon}
                      alt="submit"
                      className="mr-2"
                    />
                    Submit
                  </button>
                </div>
              </div>

              <ReadingQuestionStates question={question}/>
            </div>

            <div className="flex w-full justify-evenly xl:w-[50%] xl:justify-between mx-auto mt-4">
              <button 
                className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isFirstQuestion() ? 'invisible' : ''}`}
                onClick={displayPreviousQuestion}
              >
                <img src={PreviousArrowSvg} alt="previous" className="md:mr-2" />
                <p className="hidden md:block text-bodyr text-white">Previous</p>
              </button>
              <button 
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={() => navigate(0)}
              >
                <p className="hidden md:block text-bodyr text-white">Try Again</p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
              <div>
                <select 
                  className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                  defaultValue={question?.id}
                  onChange={(e) => handleQuestionChange(e.target.value)}
                >
                  {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
                </select>
              </div>
              <button 
                className={`flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer ${isLastQuestion() ? 'invisible' : ''}`}
                onClick={displayNextQuestion}
              >
                <p className="hidden md:block text-bodyr text-white">Next</p>
                <img src={NextArrowSvg} alt="next" className="md:ml-2" />
              </button>
            </div>

            {
              isSubmitted && (
                <div ref={answerSectionRef} className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                  <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                    <p className="text-h4m text-center">Original Answer</p>
                    <div className="mt-[1rem]">
                      <span dangerouslySetInnerHTML={{ __html: displayOriginalAnswer() }} />
                    </div>
                  </div>
                  <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                    <p className="text-h4m text-center">Your Answer</p>
                    <div className="mt-[1rem]">
                      <span dangerouslySetInnerHTML={{ __html: displayUserAnswer() }} />
                    </div>
                  </div>
                </div>     
              )
            }          
          </>
        )
      }
    </div>
  )
}

export default FillInTheBlankQuesiton
