import axiosInstance from "../configs/axiosConfig"

export function practiceWeeklyPrediction(userId: number) {
    const data = {
        user_id: userId
    }

    return axiosInstance.post(`practice/practice`, data)
}

export function getPracticeQuestion(userId: number, categoryId: number, qtype: number, qquality: number, myprac: number, labelselect: number, frecuency: number, qattempt: number, search: string, questionId?: number) {
    const data: {
        user_id: number
        categoryId: number
        qtype: number
        qquality: number
        myprac: number
        labelselect: number
        frecuency: number
        qattempt: number
        search: string
        questionId?: number
    } = {
        user_id: userId,
        categoryId,
        qtype,
        qquality,
        myprac,
        labelselect,
        frecuency,
        qattempt,
        search,
    }
    
    if (questionId !== undefined) {
        data.questionId = questionId // Add questionId conditionally
    }

    return axiosInstance.post(`practice/practice-question`, data)
}

export function updateQuestionState(userId: number, questionId: number, stateType: number, questionCategory: number, note: string) {
    const data = {
        user_id: userId,
        type: stateType,
        id: questionId,
        question_cat: questionCategory,
        note: note,
    }

    return axiosInstance.post(`practice/update-question-state`, data)
}

export function practiceNowWithFilter(userId: number, categoryId: number, questionId: number, qType: number, qQuality: number, myPrac: number, labelSelect: number, frequency: number) {
    const data = {
        userId: userId,
        categoryId: categoryId,
        questionId: questionId,
        qType: qType,
        qQuality: qQuality,
        myPrac: myPrac,
        labelSelect: labelSelect,
        frequency: frequency,
    }

    return axiosInstance.post(`practice/practice-now-with-filter`, data)
}

export function updateAttempt(userId: number, categoryId: number, questionId: number, qType: number, qQuality: number, myPrac: number, labelSelect: number, frequency: number) {
    const data = {
        userId: userId,
        categoryId: categoryId,
        questionId: questionId,
        qType: qType,
        qQuality: qQuality,
        myPrac: myPrac,
        labelSelect: labelSelect,
        frequency: frequency,
    }

    return axiosInstance.post(`practice/update-attempt`, data)
}

export function loadMoreSamples(questionId: number, offset: number) {
    const data = {
        questionId: questionId,
        offset: offset
    }

    return axiosInstance.post(`practice/load-more-samples`, data)
}

export function deductCreditUser(userId: number, category: number) {
    const data = {
        userId: userId,
        category: category
    }

    return axiosInstance.post(`practice/deduct-credit-user`, data)
}

export function getAllMockQuestions(userId: number, categoryId: number, questionId: number, qType: number, qQuality: number, myPrac: number, labelSelect: number, frequency: number) {
    const data = {
        userId: userId,
        catId: categoryId,
        questionId: questionId,
        qType: qType,
        qQuality: qQuality,
        myPrac: myPrac,
        labelSelect: labelSelect,
        frequency: frequency,
    }

    return axiosInstance.post(`practice/all-mock-question`, data)
}